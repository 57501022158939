import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  Modal,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AuthHeader } from '../../components';
import { shopName } from '../../features/frontend/frontendSlice';
import { fetchUserDetailRequest } from '../../features/frontend/frontendApi';
import { frontendAuthRequest } from '../../services/api';
import './ProfilePage.less';

function ProfilePage() {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const shop = useSelector(shopName);
  const [profile, setProfile] = useState({});
  const [fullName, setFullName] = useState('');
  const [modalConfirm, setModalConfirm] = useState(null);

  const generateProfileUpdateFormatFromData = useCallback(
    (data) => {
      const custom_fields = [
        {
          key: 'address',
          value: data.address,
        },
        {
          key: 'date_of_birth',
          value: moment(data.date_of_birth).format('YYYY-MM-DD'),
        },
        {
          key: 'phone_number',
          value: data.contact,
        },
      ];
      return {
        id: profile.id,
        name: data.name,
        surname: data.surname,
        email: data.email,
        phone_number: data.contact,
        custom_fields,
      };
    },
    [profile.id],
  );

  const handleProfileUpdate = useCallback(
    (data) => {
      // Update User Profile
      const eventId = 1; //localStorage.getItem('fe-eventId');
      const locale = localStorage.getItem('lang') || 'en';
      setLoading(true);

      if (!data) return false;
      const newFormat = generateProfileUpdateFormatFromData(data);

      setModalConfirm(null);

      if (image) {
        newFormat.avatar = image;
      }

      //console.log('newFormat', newFormat);

      frontendAuthRequest()
        .post(`/api/business/v1/attendees/update/${eventId}?locale=${locale}`, {
          participant: newFormat,
        })
        .then((resp) => {
          setFullName(
            resp.data.participant.name + ' ' + resp.data.participant.surname,
          );
        })
        .catch((err) => {})
        .finally((res) => {
          setLoading(false);
        });
    },
    [generateProfileUpdateFormatFromData, image],
  );

  const beforeUpload = (file) => false;

  const handleImageChange = (info) => {
    setLoading(true);
    getBase64(info.file, (imageUrl) => {
      setImage(imageUrl);
      setLoading(false);
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const dummyRequest = ({ file, onSuccess }) =>
    setTimeout(() => {
      onSuccess('ok');
    }, 0);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinishFailed = (errorInfo) => {
    alert('Errore nel Form');
  };

  useEffect(() => {
    fetchUserDetailRequest().then((response) => {
      //console.log('resp', response);
      if (!response.error) {
        setProfile(response.data);
        setFullName(response.data.name + ' ' + response.data.surname);
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      address: profile.address,
      contact: profile.phone_number,
      date_of_birth: moment(profile.date_of_birth),
      gender: profile.gender === 'male',
      name: profile.name,
      surname: profile.surname,
      email: profile.email,
    });
  }, [form, profile]);

  return (
    <div
      className="main-page user-profile-page bg-light-gray"
      style={{ minHeight: '100vh' }}
    >
      <AuthHeader title={shop} />

      {modalConfirm && (
        <Modal
          open={modalConfirm}
          title={t('frontend:profile.message.confirm')}
          onCancel={() => {
            setModalConfirm(null);
          }}
          footer={[
            <Button
              key="back"
              className="round-button"
              onClick={() => {
                setModalConfirm(null);
              }}
            >
              {t('frontend:Cancel')}
            </Button>,

            <Button
              className="modal-button"
              onClick={() => {
                handleProfileUpdate(modalConfirm);
              }}
            >
              OK
            </Button>,
          ]}
        >
          {t('frontend:profile.message.confirm_update')}
        </Modal>
      )}
      <div className="wrapperScroll">
        <div className="profile-title">Profilo</div>

        {/* Avatar */}
        <div className="text-center pt-4">
          <Upload
            multiple={false}
            className="cursor-pointer position-relative"
            showUploadList={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            customRequest={dummyRequest}
            onChange={handleImageChange}
          >
            <Avatar src={image || profile.avatar} size={120} />
          </Upload>
          <div className="profile-fullname">{fullName}</div>
        </div>

        <Row
          style={{
            padding: '0 20px 0 20px',
            maxWidth: '920px',
            margin: 'auto',
            marginBottom: '20px',
          }}
          className="bg-light-gray"
        >
          <Col xs={24}>
            <div className="w-100 py-2"></div>
            <Spin spinning={loading} delay={300}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 18, offset: 3 }}
                  style={{ minHeight: 'calc(80vh - 85px)' }}
                >
                  <Card className="card-profile">
                    <div className="user-form">
                      <Form
                        {...layout}
                        form={form}
                        onFinish={(data) => {
                          setModalConfirm(data);
                        }}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Row>
                          <Col xs={24} md={{ span: 11 }}>
                            <Form.Item
                              labelAlign="left"
                              name={'name'}
                              label={t('userprofile.label.name')}
                              rules={[{ required: true }]}
                            >
                              <Input
                                size="large"
                                placeholder={t('userprofile.placeholder.name')}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              labelAlign="left"
                              name={'surname'}
                              label={t('userprofile.label.surname')}
                              rules={[{ required: true }]}
                            >
                              <Input
                                size="large"
                                placeholder={t(
                                  'userprofile.placeholder.surname',
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              labelAlign="left"
                              name={'email'}
                              label={t('userprofile.label.email')}
                              rules={[{ required: true, type: 'email' }]}
                            >
                              <Input
                                type={'email'}
                                size="large"
                                placeholder={t('userprofile.placeholder.email')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item
                          labelAlign="left"
                          name={'contact'}
                          label={t('userprofile.label.contact')}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type={'text'}
                            size="large"
                            placeholder={t('userprofile.placeholder.contact')}
                            prefix={<span className="mr-1">+39</span>}
                            style={{ borderRadius: '5px' }}
                          />
                        </Form.Item>

                        <Form.Item
                          labelAlign="left"
                          name={'address'}
                          label={t('userprofile.label.address')}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type={'text'}
                            size="large"
                            required
                            placeholder={t('userprofile.placeholder.address')}
                          />
                        </Form.Item>

                        <Form.Item
                          labelAlign="left"
                          name={'date_of_birth'}
                          label={t('userprofile.label.date_of_birth')}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            showToday={false}
                            htmlType={'date'}
                            format={'DD/MM/YYYY'}
                            style={{
                              width: '100%',
                              border: 0,
                              borderRadius: '5px',
                            }}
                            size="large"
                          />
                          {/* <Input type={"date"} size="large" required placeholder={ t("userprofile.placeholder.date_of_birth") } /> */}
                        </Form.Item>

                        <Form.Item
                          wrapperCol={{ span: 24 }}
                          className="text-center"
                        >
                          <Button
                            className="btnUpdate"
                            shape="round"
                            size="large"
                            htmlType="submit"
                          >
                            {t('userprofile.button.update')}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ProfilePage;
