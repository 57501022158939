import { ClockCircleOutlined, FormOutlined } from '@ant-design/icons';
import { Modal, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import { rszDrg } from './resizeCard';
import { useTranslation } from 'react-i18next';

function ScheduleCard({ schedule }) {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
    noteInfo(getNotesFromSchedule(schedule.participant));
    return false;
  };

  const getNotesFromSchedule = (participant) => {
    let r,
      cf = participant?.custom_fields || [];
    for (r = 0; r < cf.length; r++) {
      if (cf[r].key === 'notes') return cf[r].value;
    }
    return '';
  };

  const noteInfo = (note) => {
    Modal.info({
      title: t('calendar.appointmentmodal.note'),
      closable: true,
      content: <div>{note}</div>,
      onOk() {},
    });
  };

  return (
    <div
      style={{
        position: 'relative',
        background: schedule.color,
        height: '100%',
        padding: '5px',
        color: '#fff',
        border:
          schedule.created_by === 'client'
            ? schedule.updated_by === 'client'
              ? '2px solid red'
              : schedule.updated_by === 'hairdresser' ||
                schedule.updated_by === 0
              ? '2px solid green'
              : 'none'
            : 'none',
      }}
    >
      <Typography.Text
        className="text-white truncate"
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          height: '18px',
          color: 'inherit',
        }}
        title={schedule.title}
      >
        {schedule.title}
      </Typography.Text>
      {schedule.services &&
        !!schedule.services.length &&
        schedule.services.map((item) => (
          <div
            className="d-flex align-items-center"
            key={`schedule-${schedule.id}-service-${item.id}`}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9.3"
                cy="9"
                r="7.5"
                fill={item.color || '#D4D3CF'}
                stroke="#FFFDFD"
                strokeWidth="3"
              />
            </svg>

            <Typography.Text
              className="mx-1 truncate"
              style={{ color: 'inherit' }}
              title={item.title}
            >
              {item.title}
            </Typography.Text>
          </div>
        ))}
      <Space className="my-1">
        {schedule?.late_arrived && (
          <ClockCircleOutlined
            className="icon hover-t form-scale"
            style={{
              fontSize: 16,
              filter:
                'invert(48%) sepia(79%) saturate(100) hue-rotate(0deg) brightness(118%) contrast(119%)',
            }}
          />
        )}

        {schedule.participant.display_notes && (
          <FormOutlined
            style={{ fontSize: '16px', paddingTop: '1px' }}
            onClick={handleClick}
          />
        )}

        {schedule.participant.new_participant && (
          <div style={{ fontSize: '15px' }}>N</div>
        )}
      </Space>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '40px',
          height: '32px',
          background: 'url(updown.png) 50% 50% no-repeat',
          opacity: '0.3',
          cursor: 'ns-resize',
          zIndex: '1000',
        }}
        onClick={(e) => {
          e.stopPropagation();
          rszDrg.noclick = 0;
        }}
        onMouseDown={(e) => rszDrg.handleDown(e, schedule)}
        onTouchStart={(e) => rszDrg.handleDown(e, schedule)}
      ></div>
    </div>
  );
}

ScheduleCard.propTypes = {
  schedule: PropTypes.object.isRequired,
};

export default ScheduleCard;
