import React from 'react';
import { logHandler } from '../../../handlers/LogHandler';
import { t } from 'i18next';

/**
 * Error handler most commonly used to request after certain time
 *
 * @returns {ErrorRenderHandler}
 */
class ErrorRenderHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logHandler.error(this.props, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ position: 'relative', margin: '20px', zIndex: 5 }}>
          <p>{t('common:error_occurred')}</p>
          <button
            className="btn btn-primary"
            type="primary"
            onClick={(e) => this.setState({ hasError: false })}
          >
            Reload
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorRenderHandler;
