// resize appointment by drag
// import { updateDraggedScheduleRequest } from '../../features/calendar/CalendarApi';
import moment from 'moment';
import { updateScheduleRequest } from '../../features/schedule/scheduleApi';
import {
  CustomWindowEvent,
  triggerEventListenerRefresh,
} from '../../services/event';
import { supportsTouch, getUnlock } from '../calendar/util/lockdrag';

const rszDrg = {
  isTouch: 0,
  noclick: 0,
  handTmout: 0,
  ev: {},

  // events
  handleDown: function (e, schedule) {
    rszDrg.isTouch = e.type.substring(0, 5) === 'mouse' ? 0 : 1;

    if (!getUnlock() && supportsTouch) {
      e.stopPropagation();
      e.preventDefault();
      rszDrg.ev.count = 0;
      return false;
    }

    if (!rszDrg.isTouch) {
      e.stopPropagation();
      e.preventDefault();
    }

    rszDrg.noclick = 1;

    let prn = e.target.parentNode.parentNode,
      hy = prn.offsetHeight,
      el = e.target;

    //console.log('handleDown', prn.offsetHeight, ' style ', prn.style.height);

    prn.style.maxHeight = 'none';

    rszDrg.ev = {
      count: 1,
      iy: rszDrg.coordy(e),
      ih: hy,
      dy: 0,
      percentage: 1,
      hy,
      prn,
      el,
      schedule,
    };

    if (!rszDrg.isTouch) {
      document.addEventListener('mousemove', rszDrg.handleMove, false);
      document.addEventListener('mouseup', rszDrg.handleUp, false);
    } else {
      el.addEventListener('touchmove', rszDrg.handleMove, false);
      el.addEventListener('touchend', rszDrg.handleUp, false);
    }

    return false;
  },

  handleMove: function (e) {
    e.preventDefault();
    if (!rszDrg.ev.count) return false;
    rszDrg.ev.count++;

    let ev = rszDrg.ev,
      y = rszDrg.coordy(e);

    ev.dy = y - ev.iy;
    ev.hy = Math.round(ev.ih + ev.dy);
    if (ev.hy < 40) ev.hy = 40;

    ev.prn.style.height = ev.hy + 'px';

    return false;
  },

  handleUp: function (e) {
    e.preventDefault();
    if (!rszDrg.ev.count) return false;
    let ev = rszDrg.ev;

    ev.prn.style.maxHeight = ev.hy + 'px';

    if (!rszDrg.isTouch) {
      document.removeEventListener('mousemove', rszDrg.handleMove, false);
      document.removeEventListener('mouseup', rszDrg.handleUp, false);
    } else {
      ev.el.removeEventListener('touchmove', rszDrg.handleMove, false);
      ev.el.removeEventListener('touchend', rszDrg.handleUp, false);
    }

    ev.count = 0;

    // enable / disabled click
    if (rszDrg.handTmout) clearTimeout(rszDrg.handTmout);
    rszDrg.handTmout = setTimeout(() => {
      rszDrg.noclick = 0;
    }, 300);

    // calculate % of height update and trigger update
    ev.percentage = ev.hy / ev.ih;
    if (ev.percentage !== 1) {
      rszDrg.updateDuration(ev);
    }

    return false;
  },

  //---------------------------------------
  // update appointment duration

  updateDuration: async function (data) {
    let diff,
      dateEnd,
      newDiff,
      dend = new Date(data.schedule.end_date).getTime(),
      dstart = new Date(data.schedule.start_date).getTime(),
      perc = data.percentage,
      services = rszDrg.clone(data.schedule.services);

    diff = (dend - dstart) / 1000; // seconds
    newDiff = Math.round(diff * perc);

    // step of 5 minute = 300 sec
    newDiff = Math.round(newDiff / 300) * 300;

    dateEnd = moment(data.schedule.start_date)
      .add(newDiff, 'seconds')
      .format('YYYY-MM-DD HH:mm');

    //console.log('delta time:', diff, ' | dateEnd:', dateEnd);

    // update durations
    services = services.map((srv) => {
      let duration = srv.duration * perc;
      srv.duration = Math.round(duration / 300) * 300; // approx to 5 minutes
      return srv;
    });

    //console.log('old services:', data.schedule.services);
    //console.log('new services:', services);

    let newData = {
      id: data.schedule.id,
      startDate: data.schedule.start_date,
      endDate: dateEnd,
      staffId: data.schedule.column_id,
      operator: data.schedule.operator,
      participantId: data.schedule.participant.id,
      phoneNumber: data.schedule?.phone_number || '',
      service: services,
      new_participant: data.schedule.participant.new_participant,
    };

    // update dates
    const dayDate = newData.startDate.substring(0, 10);

    //console.log('dayDate', dayDate); //  schedule  data.schedule
    //console.log('newData', newData);

    updateScheduleRequest(newData).then(() => {
      triggerEventListenerRefresh(CustomWindowEvent.SCHEDULE_REFRESH, dayDate);
    });
  },

  //-------------------------------
  coordy: function (e) {
    if (rszDrg.isTouch) return e.changedTouches[0].pageY;
    return e.pageY;
  },

  clone: function (a) {
    if (!a) return;
    var d,
      c = typeof a.length == 'undefined' ? {} : [];
    for (d in a) c[d] = typeof a[d] == 'object' ? rszDrg.clone(a[d]) : a[d];
    return c;
  },
}; //

export { rszDrg };
