import {
  Form,
  Select,
  Input,
  Card,
  Typography,
  Divider,
  Button,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import { TrashIcon } from '..';
import ErrorRenderHandler from '../calendar/handlers/ErrorRenderHandler';
import { useEffect } from 'react';
import { logHandler } from '../../handlers/LogHandler';
import { useSelector } from 'react-redux';
import { selectServiceList } from '../../features/service/serviceSelector';

const { Option } = Select;
const { TextArea } = Input;

function ServiceCard({
  className,
  serviceLists,
  form,
  staff,
  field,
  removeCard,
  isOperator,
  toggleIsOperator,
}) {
  const allServices = useSelector(selectServiceList);
  const [service, setService] = useState(null);
  const [relatedService, setRelatedService] = useState(null);

  const { t } = useTranslation();

  const alphabeticalSort = (a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  };

  const selectService = useCallback(
    (serviceId, duration = null) => {
      if (!serviceId) {
        return;
      }
      duration = typeof duration === 'object' ? null : duration;
      const service = serviceLists.find((item) => item.id === serviceId);
      if (!service) {
        selectService(null);
        return;
      }
      const relatedName =
        service &&
        service.service_ids.map((item) =>
          allServices.find((ser) => ser.id === item),
        );
      setService(service);

      form.setFields([
        {
          name: ['services', field.fieldKey, 'service_id'],
          value: service.id,
        },
        {
          name: ['services', field.fieldKey, 'duration'],
          value: duration ?? service.duration,
        },
      ]);
      setRelatedService(relatedName[0] ?? null);
    },
    [form, field.fieldKey, serviceLists, allServices],
  );

  const allFormServices = form.getFieldValue(['services']);
  const addServiceToForm = (newService) => {
    if (serviceLists.find((item) => item.id === newService.id)) {
      form.setFieldsValue({
        services: [
          ...allFormServices,
          {
            ...newService,
            id: null,
            service_id: newService.id,
            hour: 0,
            minute: 0,
          },
        ],
      });
      return;
    }
    notification['error']({
      message: 'Staff does not perform this service',
    });
  };

  useEffect(() => {
    try {
      selectService(
        form.getFieldValue(['services', field.fieldKey, 'service_id']),
        form.getFieldValue(['services', field.fieldKey, 'duration']),
      );
    } catch (error) {
      logHandler.error('Couldnot calculate service');
    }
  }, [field.fieldKey, form, selectService]);

  return (
    <Card className={`${className} service-card`}>
      <ErrorRenderHandler>
        <div className="service-card-header py-0 bg-white">
          <div className="d-flex flex-row w-100">
            <div style={{ flex: 5 }}>
              <Form.Item
                name={[field.name, 'service_id']}
                key={[field.fieldKey, 'service_id']}
                label={null}
                style={{ margin: '10px 0px' }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  data-testid="service-list"
                  placeholder={t(
                    'calendar.appointmentmodal.placeholder.service',
                  )}
                  className="py-2 w-100"
                  bordered={false}
                  onChange={selectService}
                >
                  {[...serviceLists].sort(alphabeticalSort).map((item) => (
                    <Option
                      key={`service-option-id-${item.id}`}
                      value={item.id}
                    >
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <span className="border-right"></span>
            <div style={{ flex: 2 }}>
              <input
                type="text"
                readOnly={true}
                value={`${t('common:currency')} ${
                  service ? service.price : '0'
                }`}
                className="ant-input py-2 my-0 h-100"
              />
            </div>
            <div style={{ flex: 2 }}>
              <Form.Item
                name={[field.name, 'duration']}
                key={[field.fieldKey, 'duration']}
                label={null}
                style={{ margin: '10px 0px' }}
              >
                <Select
                  data-testid="service-time"
                  placeholder={t(
                    'calendar.appointmentmodal.placeholder.duration',
                  )}
                  className="py-2 w-100"
                  name={'duration'}
                  bordered={false}
                  onChange={selectService}
                >
                  {new Array(120).fill(0).map((item, index) => (
                    <Option
                      key={`service-option-id-${index}`}
                      value={(index + 1) * 300}
                    >
                      {Math.floor((index + 1) / 12)}h{' '}
                      {Math.floor((index + 1) % 12) * 5}m
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {typeof removeCard === 'function' && (
              <div style={{ flex: 1, borderLeft: '1px solid #e3e3e3' }}>
                <Button
                  className="w-100 h-100 secondary-button-ghost-square"
                  style={{ borderRadius: '0%' }}
                  onClick={removeCard}
                >
                  <img src={TrashIcon} alt="Remove Card" />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="service-card-body bg-light-yellow px-0 pb-4">
          {service && !!service.service_identifier && (
            <div className="px-4 pt-4">
              <span className="cursor-pointer py-2 h6 text-bold">
                {service.service_identifier}
              </span>
              <span className="px-1"></span>
            </div>
          )}

          {service && !!service.service_identifier && (
            <Divider className="bg-light-yellow" />
          )}

          <div className="form-group px-4 pt-3 d-flex align-items-center">
            <label
              className="agungi-label h-100"
              style={{ flex: 1 }}
              htmlFor="CON"
            >
              {t('calendar.appointmentmodal.staff')}
            </label>

            <div style={{ flex: 4 }}>
              <div className="d-flex align-items-center">
                <div className="w-100">
                  <Typography.Text className="ant-input mx-2 py-2 text text-bold">
                    {isOperator
                      ? staff.name || 'Nome Cognome'
                      : `${t('calendar.operator_none')}`}
                  </Typography.Text>
                </div>
                <div>
                  {isOperator ? (
                    <CloseCircleOutlined
                      style={{ fontSize: '20px', paddingLeft: '8px' }}
                      className="mx-2 cursor-pointer"
                      onClick={toggleIsOperator}
                    />
                  ) : (
                    <UserOutlined
                      style={{ fontSize: '20px', paddingLeft: '8px' }}
                      className="mx-2 cursor-pointer"
                      onClick={toggleIsOperator}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {!!relatedService && (
            <div className="form-group px-4 pt-3 d-flex align-items-center">
              <label
                className="agungi-label h-100"
                style={{ flex: 1 }}
                htmlFor="CON"
              >
                {t('calendar.appointmentmodal.related')}
              </label>

              <div style={{ flex: 4 }}>
                <Typography.Text
                  style={{ display: 'flex' }}
                  className="ant-input w-100 flex-row justify-content-between align-items-center text text-bold"
                >
                  <Typography.Text>
                    {relatedService.title} ({t('common:currency')}{' '}
                    {relatedService.price}) {relatedService.readableDuration}
                  </Typography.Text>

                  {!allFormServices
                    .map((item) => item && item.service_id)
                    .includes(relatedService.id) && (
                    <Button onClick={() => addServiceToForm(relatedService)}>
                      {t('calendar.appointmentmodal.add_related')}
                    </Button>
                  )}
                </Typography.Text>
              </div>
            </div>
          )}
          <div className="form-group px-4 d-flex pt-2">
            <label
              className="agungi-label h-100"
              style={{ flex: 1 }}
              htmlFor="CON"
            >
              {t('calendar.appointmentmodal.note')}
            </label>

            <Form.Item
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              label={null}
              className="d-none"
            >
              <Input type={'hidden'} />
            </Form.Item>

            <Form.Item
              name={[field.name, 'note']}
              fieldKey={[field.fieldKey, 'note']}
              label={null}
              className="m-0"
              style={{ flex: 4 }}
            >
              <TextArea
                data-testid="note"
                rows={4}
                className="w-100 mx-2"
                placeholder={t('calendar.appointmentmodal.placeholder.note')}
              />
            </Form.Item>
          </div>
        </div>
      </ErrorRenderHandler>
    </Card>
  );
}

ServiceCard.propTypes = {
  serviceLists: PropTypes.array.isRequired,
  staff: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  removeCard: PropTypes.func,
  form: PropTypes.object,
  className: PropTypes.string,
};

export default ServiceCard;
