import { Button, Col, Row, Typography, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setCurrentLanguage, getCurrentLanguage } from '../services/language';
import { InbellezzaIcon } from '../components';
import './HomePage.less';

const { Title } = Typography;

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
export default function HomePage() {
  const { t } = useTranslation();

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang || 'en');
    window.location.reload();
  };

  const current = getCurrentLanguage();

  return (
    <div className="homepage bkgHome">
      <Row type="flex" align="middle" className="w-100 vh-100 text-center">
        <Col offset={6} span={12}>
          <div className="text-center">
            <img
              src={InbellezzaIcon}
              alt={'Inbellezza icon'}
              style={{
                width: '240px',
                marginBottom: '40px',
              }}
            />

            <Title level={2}>{t('common:welcome_text')}</Title>

            <Button className="primary-button btnGo" style={{ width: 200 }}>
              <Link className="lead" to="/agenda">
                {t('common:go_to_calendar')}
              </Link>
            </Button>

            <div className="text-center" style={{ margin: 30 }}>
              <Select
                defaultValue={current}
                onChange={changeLanguage}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  borderRadius: '20px',
                }}
                bordered={false}
                options={[
                  {
                    value: 'it',
                    label: t('common:italian'),
                  },
                  {
                    value: 'en',
                    label: t('common:english'),
                  },
                  {
                    value: 'fr',
                    label: t('common:french'),
                  },
                ]}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
