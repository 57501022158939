/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { logHandler } from '../../../../../handlers/LogHandler';
import { Draggable, Droppable } from '../../../dnd/DragAndDrop';
import { useSchedulesProvider } from '../../../providers/ScheduleProvider';
import { useCalendarTheme } from '../../../theme';
import ScheduleCard from '../../schedule';
import {
  CustomWindowEvent,
  triggerEventListener,
  triggerEventListenerRefresh,
} from '../../../../../services/event';
import { updateScheduleRequest } from '../../../../../features/schedule/scheduleApi';
import { useTranslation } from 'react-i18next';

// touch drag drop
import { tchDrgDrp } from '../../../dnd/touchDragDrop.js';
import {
  zeroNum,
  moveMultiAppointment,
  diffHoursMin,
} from '../../../../../components/util/Generic';
import { supportsTouch } from '../../../util/lockdrag.js';

/**
 * Schedule layer of calendar
 *
 * @param {hour} int Current hour of schedule Layer
 * @param {column} object Current column
 * @param {schedules} array List of schedules to render
 * @param {onCellClick} function
 * @returns {JSX}
 */
function ScheduleLayer({
  hour,
  column,
  currentDate,
  schedules = [],
  onCellClick,
}) {
  const { computedStyles, theme, dateOption } = useCalendarTheme();
  const { scheduleClick, scheduleDblClick, dragAndDrop } =
    useSchedulesProvider();

  /**
   * Generate Schedule For The Cell
   *
   * @param {array} schedules Schedules list
   * @param {integer} hour Cell Current hour
   * @param {integer} minute Cell Current minute
   * @param {object} theme Theme for the schedule
   * @param {function} clickedSchedule Triggers when schedule is clicked
   * @param {function} doubleClickedSchedule Triggers when schedule is double clicked
   * @returns
   */

  const { t } = useTranslation();

  const generateSchedule = useCallback(function ScheduleGenerate(
    schedules,
    hour,
    minute,
    theme,
  ) {
    try {
      const schedule = schedules.filter((row) => {
        const date = moment(row.start_date);
        const currentHour = date.format('HH');
        const currentMinute = date.format('mm');
        return (
          parseInt(currentHour) === parseInt(hour) &&
          currentMinute >= minute &&
          currentMinute <= minute + 14
        );
      });
      if (!schedule.length) {
        return <></>;
      }
      return (
        <>
          {schedule.map((row, index) => (
            <Draggable
              item={row}
              key={`schedule-${row.id}-${row.hour}`}
              index={index}
            >
              <ScheduleCard
                schedule={row}
                theme={theme}
                clickedSchedule={scheduleClick}
                doubleClickedSchedule={scheduleDblClick}
                containerStyle={{ zIndex: 2 + hour }}
              />
            </Draggable>
          ))}
        </>
      );
    } catch (error) {
      logHandler.log('error occured on generate schedule', error?.message);
      logHandler.error(error);
      return <></>;
    }
  }, []);

  const isHoliday = useCallback((currentHour, minute) => {
    return (
      !!column.holidays.length &&
      column.holidays.some((item) => {
        let currentDate = moment(dateOption.date).set({
          hour: currentHour,
          minute: minute,
        });
        let startDate = moment(item.start_date).subtract(1, 'minute');
        let endDate = moment(item.end_date).subtract(1, 'minute');
        return currentDate.isBetween(startDate, endDate);
      })
    );
  });

  const handleDrop = (schedule, minute, prevSchedule) => {
    if (prevSchedule) {
      const date = moment(prevSchedule.start_date);
      const currentHour = date.format('HH');
      const currentMinute = date.format('mm');
      const isOnSameCell =
        parseInt(currentHour) === parseInt(hour) && currentMinute === minute;
      if (isOnSameCell) {
        return;
      }
    }

    triggerEventListener(CustomWindowEvent.SCHEDULE_DROPPED, {
      draggableId: schedule.id,
      destination: {
        droppableId: `droppable-${column.id}-${currentDate}-${hour}-${minute}`,
      },
    });
  };

  // Touch start custom Handle in cell
  const tchHandleStart = (e) => {
    tchDrgDrp.tchStartTime = new Date().getTime();
    tchDrgDrp.countMove = 0;
    tchDrgDrp.isMenuVisible = 0;
    return true;
  };

  const tchHandleMove = (e) => {
    tchDrgDrp.countMove++;
    return true;
  };

  // Touch end custom handle
  const tchHandleEnd = (params) => {
    let par = tchDrgDrp.tchHandleDrop(params);
    const tms = new Date().getTime();
    const deltaTime = tms - tchDrgDrp.tchStartTime;

    if (
      !par &&
      tchDrgDrp.countMove < 6 &&
      deltaTime > 500 &&
      !tchDrgDrp.isMenuVisible &&
      !tchDrgDrp.scheduleId
    ) {
      // console.log('touch CONTEXT menu');
      if (params.e) {
        params.e.preventDefault();
        params.e.stopPropagation();
      }
      eventContextMenu(params.minute);
    }

    tchDrgDrp.tchStartTime = 0;

    if (!par) return false;

    triggerEventListener(CustomWindowEvent.SCHEDULE_DROPPED, {
      draggableId: par.draggableId,
      destination: {
        droppableId: par.droppableId,
      },
    });
  };

  // right click on cell
  const eventContextMenu = (minute) => {
    const type = localStorage.getItem('copy_type');

    if (!tchDrgDrp.scheduleId) {
      if (!type) {
        // single
        tchDrgDrp.isMenuVisible = 1;
        contextMove(column, currentDate, hour, minute);
      } else {
        // multi
        const dtFrom = type.split(' ');
        const fromDate = dtFrom[0];
        const toDate = currentDate;

        if (fromDate === toDate) {
          Modal.warning({
            title: t('calendar.warning'),
            content: (
              <div>
                <p>{t('calendar.no_paste_same_day')}</p>
              </div>
            ),
          });
          return;
        }
        //confirm
        Modal.confirm({
          title: t('calendar.card.title_confirm'),
          content: (
            <div>
              <p>{t('calendar.card.move_confirm_all')}</p>
            </div>
          ),
          onOk() {
            const copySchedule = JSON.parse(
              localStorage.getItem('copy_schedule') || [],
            );

            if (!copySchedule.length) {
              Modal.warning({
                title: t('calendar.warning'),
                content: t('calendar.no_paste_appointment'),
              });
              return;
            }

            // currentDate, hour, minute
            const param = {
              schedules: copySchedule,
              hourMinTo: zeroNum(hour) + ':' + zeroNum(minute),
              dateTo: toDate,
              dateFrom: fromDate,
              hourMinFrom: dtFrom[1] || 0,
            };

            const delta = diffHoursMin(param.hourMinTo, param.hourMinFrom);
            //console.log(param.hourMinTo, param.hourMinFrom, 'delta', delta);

            moveMultiAppointment(param, delta);
          },
        });
      }
    }
  };

  function contextMove(column, currentDate, hour, minute) {
    let copySchedule = localStorage.getItem('copy_schedule');

    //console.log('cell dx:', currentDate, hour, minute, ' | ', column);
    //console.log('to copy:', copySchedule ? JSON.parse(copySchedule) : 'none');

    if (!copySchedule) {
      Modal.info({
        title: 'Info',
        content: (
          <div>
            <p>{t('calendar.card.move_nocopied')}</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      copySchedule = JSON.parse(copySchedule);

      Modal.confirm({
        title: t('calendar.card.title_confirm'),
        content: (
          <div>
            <p>{t('calendar.card.move_confirm')}</p>
          </div>
        ),
        onOk() {
          localStorage.removeItem('copy_schedule');
          const param = {
            currentDate,
            hour,
            minute,
            column,
            copySchedule,
          };
          moveAppointment(param);
        },
      });
    }
  }

  function moveAppointment(param) {
    let startDate =
      param.currentDate +
      ' ' +
      zeroNum(param.hour) +
      ':' +
      zeroNum(param.minute);

    let dend = new Date(param.copySchedule.end_date).getTime(),
      dstart = new Date(param.copySchedule.start_date).getTime(),
      delta = (dend - dstart) / 1000; // seconds

    let endDate = moment(startDate)
      .add(delta, 'seconds')
      .format('YYYY-MM-DD HH:mm');

    let newData = {
      id: param.copySchedule.id,
      startDate: startDate,
      endDate: endDate,
      staffId: param.column.id,
      operator: param.copySchedule.operator,
      participantId: param.copySchedule.participant.id,
      phoneNumber: param.copySchedule?.phone_number || '',
      service: param.copySchedule.services,
      new_participant: param.copySchedule.participant.new_participant,
      appointment_status: null,
    };

    updateScheduleRequest(newData).then(() => {
      triggerEventListenerRefresh(
        CustomWindowEvent.SCHEDULE_REFRESH,
        param.currentDate,
      );
    });
  }

  const renderCell = (minute) => {
    if (!dragAndDrop || supportsTouch) {
      tchDrgDrp.cellSize = {
        w: computedStyles.cellStyle.width,
        h: computedStyles.cellStyle.height,
      };

      return (
        <div
          style={computedStyles.cellStyle}
          className={`${
            isHoliday(hour, minute) ? 'leave' : 'agenda-hover'
          } calendar-hour agenda-bg-transparent position-relative`}
          onClick={(e) =>
            onCellClick({
              column: column,
              date: currentDate,
              event: e,
              hour,
              minute: minute,
            })
          }
          onContextMenu={(e) => {
            if (e.type === 'contextmenu') {
              // console.log('event onContextMenu');
              e.preventDefault();
              eventContextMenu(minute);
              return false;
            }
            return true;
          }}
          onTouchStart={(e) => {
            tchHandleStart(e);
          }}
          onTouchMove={(e) => {
            tchHandleMove(e);
          }}
          onTouchEnd={(e) =>
            tchHandleEnd({
              column: column,
              date: currentDate,
              event: e,
              hour,
              minute: minute,
            })
          }
          data-droparam={`${JSON.stringify({
            column: column,
            date: currentDate,
            hour,
            minute: minute,
          })}`}
        >
          {!!schedules.length &&
            generateSchedule(schedules, hour, minute, theme)}
        </div>
      );
    }

    return (
      <Droppable
        item={{ column: column, date: currentDate, hour, minute: minute }}
        onDrop={(item) => handleDrop(item, minute, schedules)}
        isDropDisabled={false}
        // isDropDisabled={isHoliday(hour, minute)}
        className={`${
          isHoliday(hour, minute) ? 'leave' : 'agenda-hover'
        } calendar-hour agenda-bg-transparent position-relative`}
        style={{ ...computedStyles.cellStyle }}
        onClick={(e) =>
          onCellClick({
            column: column,
            date: currentDate,
            event: e,
            hour,
            minute: minute,
          })
        }
        onContextMenu={(e) => {
          e.preventDefault();
          eventContextMenu(minute);
          return false;
        }}
      >
        {!!schedules.length && generateSchedule(schedules, hour, minute, theme)}
      </Droppable>
    );
  };

  return (
    <div className="calendar-hours agenda-bg-transparent">
      {renderCell(0)}
      {renderCell(15)}
      {renderCell(30)}
      {renderCell(45)}
    </div>
  );
}

export default React.memo(ScheduleLayer);
