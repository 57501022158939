import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CREATE_MODE,
  EDIT_MODE,
  NOTIFICATION_CREATED,
  NOTIFICATION_DELETED,
  NOTIFICATION_UPDATED,
} from '../../../app/constants';
import {
  selectDeleteReasons,
  //selectTimezone,
  updateAgendaSchedule,
} from '../../../features/calendar/calendarSlice';
import {
  createCustomer,
  updateCustomer,
} from '../../../features/customer/customerSlice';

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  LogoutOutlined,
  setTwoToneColor,
} from '@ant-design/icons';
import moment from 'moment';
import {
  selectServiceLoadingStatus,
  selectStaffServices,
} from '../../../features/schedule/scheduleSelector';
import {
  createSchedule,
  deleteSchedule,
  fetchAllStaffServices,
  updateSchedule,
} from '../../../features/schedule/scheduleSlice';
import { selectServiceList } from '../../../features/service/serviceSelector';
import { fetchServiceList } from '../../../features/service/serviceSlice';
import { parseApiResponseAndSendNotification } from '../../../handlers/ApiErrorNotifier';
import ErrorRenderHandler from '../../../handlers/ErrorRenderHandler';
import { logHandler } from '../../../handlers/LogHandler';
import { authRequest } from '../../../services/api';
import { getEventFromStorage } from '../../../services/token';
import {
  getApiFormatFromFormData,
  participantTransformerCollection,
  participantTransformerResource,
} from '../../../transformers/participantTransformer';
import { SearchAutocomplete } from '../../autocomplete/SearchAutocomplete';
import ServiceCard from '../../card/ServiceCard';
import { ClientForm, EditIcon, PlusIcon, TrashIcon } from '../../index';
import RejectAppointmentModal from '../AppointmentModal/RejectAppointmentModal';
import RecurringModal from './RecurringModal';
import './ScheduleModal.less';
import { serviceSplitToArraySchedule } from '../../../transformers/serviceTransformer';

setTwoToneColor('#B19499');

/**
 * Schedule Modal for creating and updating appointment
 * @param {*} param0
 * @returns JSX
 */
function ScheduleModal({
  open = true,
  appointment,
  staff,
  closeModal,
  dateOption,
}) {
  //const timezone = useSelector(selectTimezone);
  const services = useSelector(selectStaffServices);
  const allServices = useSelector(selectServiceList);
  const scheduleLoading = useSelector(selectServiceLoadingStatus);
  const deleteReasons = useSelector(selectDeleteReasons);
  const [showRepeatModal, setShowRepeatModal] = useState(false);
  const [openRejectModal, setRejectModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);

  // new Partecipant "N"
  const [newPartecipant, setNewPartecipant] = useState(
    appointment ? appointment.participant.new_participant : false,
  );

  // partecipant of appointment selected or in edit
  const editPartecipant = useRef(null);
  const editShouldRerender = useRef(false);

  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const addRelatedService = () => {
    let selectedServices = form.getFieldValue('services');
    let formServices = selectedServices.filter((item) => !!item);
    if (!formServices.length) return;
    const serviceIds = allServices.map((item) => item.id || item.service_id);
    const serviceRelatedIds = formServices
      .filter((item) => serviceIds.includes(item.id))
      .map((item) =>
        !serviceIds.includes(item.service_ids) ? item.service_ids : null,
      )
      .flatMap((item) => item);
    const remainingService = serviceRelatedIds.filter(
      (item) => !serviceIds.includes(item),
    );
    if (!remainingService.length) return false;

    // let formNewServices = allServices.filter(item => remainingService.includes(item.id))
    //     .map(item => ({...item, service_id: item.id, id: null}))
    // if(!formNewServices.length) { return false }
    // form.setFieldsValue({ services: [ ...selectedServices, ...formNewServices ] })
    return true;
  };

  const calculateTotalServicePrice = () => {
    let selectedServices = form.getFieldValue('services');
    if (!selectedServices) {
      return;
    }
    let formSelectedService = selectedServices
      .map((curr) =>
        curr ? allServices.find((item) => item.id === curr.service_id) : null,
      )
      .filter((item) => !!item);

    if (addRelatedService()) {
      return;
    }
    let total = formSelectedService.reduce((accum, curr) => {
      accum += curr.price ?? 0;
      return accum;
    }, 0);
    setTotalPrice(total);
  };

  useEffect(() => {
    if (staff.id !== 0) {
      dispatch(fetchAllStaffServices({ id: staff?.id })).then((res) =>
        calculateTotalServicePrice(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff]);

  useEffect(() => {
    if (!allServices.length) {
      dispatch(fetchServiceList()).then((res) => calculateTotalServicePrice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allServices.length, dispatch]);

  const handleUpdateOrCreateSchedule = async (e) => {
    try {
      setLoading(true);
      // Format service data to api format
      let serviceData = e.services
        .map((curr) => {
          if (curr.service_id === undefined) return null;
          let data = {
            service_id: curr.service_id,
            note: curr.note,
            duration: curr.duration,
          };
          if (curr.id) data.id = curr.id;
          return data;
        })
        .filter((curr) => curr !== null);

      // Parse Time From String And Add To End Date
      let seconds = serviceData.reduce((accum, curr) => {
        accum += parseInt(curr.duration);
        return accum;
      }, 0);

      let endDate = dateOption.date.clone().add(seconds, 'seconds');
      if (seconds === 0) {
        notification['error']({
          message: 'Could not calculate duration of your agenda',
        });
      }
      const createScheduleData = {
        startDate: appointment
          ? appointment.start_date
          : dateOption.date.format('YYYY-MM-DD HH:mm'),
        endDate: endDate.format('YYYY-MM-DD HH:mm'),
        operator: isOperator,
        staffId: staff.id,
        participantId: e.participantId,
        phoneNumber: e.telephone,
        service: serviceData,
        new_participant: newPartecipant,
      };

      // schedule creation if one_service_on_appointment
      let osoa = localStorage.getItem('one_service_on_appointment') || '';

      // update
      if (appointment) {
        // if multi => first have appointment.id other are creation!!
        if (osoa && serviceData.length > 1) {
          let multiData = serviceSplitToArraySchedule(createScheduleData);

          // update first
          let firstData = multiData.shift();
          firstData.id = appointment.id;
          handleUpdateOneSchedule(firstData, 0); // and close modal

          // create others
          multiData.forEach((singleData) => {
            handleCreateOneSchedule(singleData, 1);
          });
        } else {
          createScheduleData.id = appointment.id;
          handleUpdateOneSchedule(createScheduleData, 0); // single
        }

        return;
      }

      // create
      if (osoa && serviceData.length > 1) {
        // multile appointments with one service
        const multiCreate = serviceSplitToArraySchedule(createScheduleData);

        await Promise.all(
          multiCreate.map((singleData) => {
            return handleCreateOneSchedule(singleData, 1);
          }),
        );

        hideModalAndNotif(null, 1); // close modal
      } else {
        // create a single appointment with all services
        handleCreateOneSchedule(createScheduleData, 0);
      }
    } catch (error) {
      logHandler.error(error);
    } finally {
      setLoading(false);
    }
  };

  //
  // create one schedule
  const handleCreateOneSchedule = (createScheduleData, multi) => {
    return dispatch(createSchedule(createScheduleData))
      .then((res) => {
        if (res && res.payload && res.payload.error) {
          hideModalAndNotif(res, 2); // Notification
          return;
        }
        hideModalAndNotif(res, multi ? 2 : 3); // ok Close and Notification
      })
      .catch((error) => {
        logHandler.error(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_CREATED);
      });
  };

  //
  // update one schedule
  const handleUpdateOneSchedule = (createScheduleData, multi) => {
    dispatch(updateSchedule(createScheduleData))
      .then((res) => {
        if (!multi) hideModalAndNotif(null, 1); // close modal
        parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
      })
      .catch((error) => {
        logHandler.error(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_UPDATED);
      });
  };

  // mode: 1=close Modal | 2=Notification
  const hideModalAndNotif = (res, mode) => {
    if (mode & 1) closeModal({ shouldRerender: true });

    if (mode & 2) {
      parseApiResponseAndSendNotification(
        res.payload ? res.payload : res,
        NOTIFICATION_CREATED,
      );
    }
  };

  const createUser = (data) => {
    setLoading(true);

    dispatch(createCustomer(data))
      .then((res) => {
        setLoading(false);
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        setShowClientModal(false);
      })
      .catch((err) => {
        logHandler.error(err);
        setLoading(false);
      });
  };

  const updateUser = (data) => {
    setLoading(true);

    let oldData = getActualPartecipant();

    let apiData = getApiFormatFromFormData(data);
    apiData = { participant: { id: oldData.id, ...apiData.participant } };

    dispatch(updateCustomer(apiData))
      .then((res) => {
        setLoading(false);
        parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
        // close modal
        setShowClientModal(false);

        editPartecipant.current = participantTransformerResource(
          apiData.participant,
        );

        //const fullname = editPartecipant.current.name + ' ' + editPartecipant.current.surname;

        form.setFieldsValue({
          telephone: editPartecipant.current.phone_number || '',
        });

        editShouldRerender.current = true;
      })
      .catch((err) => {
        logHandler.error(err);
        setLoading(false);
      });
  };

  const isAppointmentToday =
    appointment?.start_date?.substring(0, 10) ===
    new Date()?.toISOString()?.substring(0, 10);

  const updateAppointmentStatus = async (data) => {
    setLoading(true);

    try {
      /*
      if (data.appointment_status === 'early_arrived') {
        data.start_date = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss');
        let diffInMinutes = moment.duration(
          moment(appointment.end_date).diff(moment(appointment.start_date)),
        );
        data.end_date = moment(data.start_date)
          .add(diffInMinutes.asMinutes(), 'minutes')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (data.appointment_status === 'early_exit') {
        data.end_date = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss');
      }
      */
      await dispatch(updateAgendaSchedule({ ...data, id: appointment.id }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      closeModal({ shouldRerender: true });
    }
  };

  const removeSchedule = async () => {
    await dispatch(deleteSchedule(appointment.id))
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_DELETED);
      })
      .catch((error) => {
        logHandler.error(error);
        parseApiResponseAndSendNotification(error, NOTIFICATION_DELETED);
      });
    closeModal({ shouldRerender: true });
  };

  const openRemoveAppointmentModal = () =>
    appointment
      ? setRejectModal(true)
      : closeModal({ shouldRerender: editShouldRerender.current });

  const handleRepeatClick = () => {
    form
      .validateFields()
      .then((res) => {
        setShowRepeatModal(true);
      })
      .catch((err) => {
        notification['error']({
          message: 'Fill All Data Before Proceeding',
          description:
            'You need to fill at least one service to make appointment recurring',
        });
        logHandler.error(err);
      });
  };

  const footer = (
    <Row align="middle">
      <Col span={8} className="text-start">
        <Button
          size="large"
          style={{ minWidth: 160 }}
          onClick={handleRepeatClick}
          className="btn btn-schedule-light"
        >
          {t('calendar.repititivemodal.placeholder.repeat')}
        </Button>
      </Col>

      <Col span={8}>
        {appointment && (
          <Button
            size="large"
            className="btn btn-schedule-light w-100"
            onClick={openRemoveAppointmentModal}
          >
            <img
              src={TrashIcon}
              alt="Remove Agenda"
              style={{
                filter: 'invert(100%)',
              }}
              className="icon hover-t form-scale"
            />
          </Button>
        )}
      </Col>

      <Col span={8} className="text-right">
        <Button
          size="large"
          style={{ minWidth: 160 }}
          onClick={() => form.submit()}
          className="btn btn-schedule-light"
        >
          {appointment
            ? t('calendar.appointmentmodal.button.update')
            : t('calendar.appointmentmodal.button.create')}
        </Button>
      </Col>
    </Row>
  );

  const title = (
    <Row align="middle" className="pt-3">
      <Col xs={24} md={8}>
        {isAppointmentToday && (
          <div className="d-flex justify-content-between">
            <Tooltip placement="bottomLeft" title={t('calendar.early_arrived')}>
              <Button
                ghost={true}
                size="small"
                onClick={() =>
                  updateAppointmentStatus({
                    appointment_status: 'early_arrived',
                    arrived: true,
                  })
                }
                className="btn half-hover-opacity mx-2"
              >
                <CheckCircleOutlined
                  className="icon hover-t form-scale"
                  style={{ fontSize: 20, color: 'green' }}
                />
              </Button>
            </Tooltip>

            <Tooltip placement="bottomLeft" title={t('calendar.early_exit')}>
              <Button
                ghost={true}
                size="small"
                onClick={() =>
                  updateAppointmentStatus({
                    appointment_status: 'early_exit',
                    arrived: true,
                  })
                }
                className="btn half-hover-opacity mx-2"
              >
                <LogoutOutlined
                  className="icon hover-t form-scale"
                  style={{
                    fontSize: 20,
                    color: 'gray',
                  }}
                />
              </Button>
            </Tooltip>

            <Tooltip placement="bottomLeft" title={t('calendar.client_late')}>
              <Button
                ghost={true}
                size="small"
                onClick={() =>
                  updateAppointmentStatus({
                    appointment_status: 'client_late',
                    arrived: true,
                  })
                }
                className="btn half-hover-opacity mx-2"
              >
                <ClockCircleOutlined
                  className="icon hover-t form-scale"
                  style={{
                    fontSize: 20,
                    color: 'red',
                  }}
                />
              </Button>
            </Tooltip>
          </div>
        )}
      </Col>

      <Col xs={18} md={8} className="mt-2">
        {appointment === null ? (
          <p className="text-center text-uppercase">
            {dateOption && dateOption.date.format('DD MMM')}{' '}
            {(dateOption && dateOption.start_time) || ''}
          </p>
        ) : (
          <p className="text-center text-uppercase m-0">
            {moment(appointment.start_date).format('DD MMM HH:mm')}
          </p>
        )}
      </Col>

      <Col xs={6} md={8} className="text-right">
        <Button
          ghost={true}
          onClick={() =>
            closeModal({ shouldRerender: editShouldRerender.current })
          }
          className="border-0"
        >
          <span className="close" style={{ position: 'relative' }}></span>
        </Button>
      </Col>
    </Row>
  );

  useEffect(() => {
    appointment &&
      form.setFieldsValue({
        participantId: appointment.participant.id,
        telephone: appointment.phone_number || '',
        services: appointment.services,
      });
  }, [appointment, form]);

  const getRecurringModalData = () => {
    let formData = form.getFieldsValue();

    formData.services = formData.services
      .map((service) => {
        let serviceData = services.filter(
          (item) => item.id === service.service_id,
        );
        return serviceData.length
          ? { ...service, service: serviceData[0] }
          : null;
      })
      .filter((x) => x);

    formData.date = dateOption;

    return formData;
  };

  const initialValues = appointment
    ? {
        participantId: appointment.participant.id,
        telephone: appointment.phone_number || '',
        services: appointment.services,
      }
    : { services: [{}] };

  const handleRecurringData = (data) => {
    setLoading(true);
    setShowRepeatModal(false);

    // Parse Time From String And Add To End Date
    let seconds = data.services
      .filter((service) => data.serviceIds.includes(service.service_id))
      .reduce((accum, curr) => {
        accum += curr.service.execution_time;
        return accum;
      }, 0);

    let endDate = data.date.date.clone().add(seconds, 'seconds');

    let eventId = getEventFromStorage();
    authRequest()
      .post(`api/business/v1/${eventId}/agenda_appointments`, {
        repetitive: data.ripeti,
        start_date: `${data.date.date.format('YYYY-MM-DD')} ${
          data.date.start_time ?? data.date.date.format('HH:mm')
        }`,
        end_date: `${data.repeat_until.format('YYYY-MM-DD')} ${endDate.format(
          'HH:mm',
        )}`,
        participant_id: data.participantId,
        operator: true,
        staff_id: data.date.staff.id,
        agenda_services_attributes: data.services
          .filter((service) => data.serviceIds.includes(service.service_id))
          .map((service) => ({
            service_id: service.service_id,
            note: service?.note,
            duration: service.duration,
          })),
      })
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_CREATED);
        closeModal({ shouldRerender: true });
        setLoading(false);
      })
      .catch((err) => {
        logHandler.error(err);
        parseApiResponseAndSendNotification(err, NOTIFICATION_CREATED);
        setLoading(false);
      });
  };

  const listRules = {
    message: t('error:appointment.repititivemodal.select_a_service'),
    validator: async (rule, value) => {
      return value.length && value.some((x) => x.hasOwnProperty('service_id'))
        ? Promise.resolve()
        : Promise.reject();
    },
  };

  const participantChange = (selectedParticipant) => {
    if (selectedParticipant) {
      form.setFieldsValue({
        participantId: selectedParticipant.id,
        telephone: selectedParticipant.phone_number || '',
      });
    }
    editPartecipant.current = selectedParticipant;
  };

  const fetchParticipant = (keyword) => {
    try {
      let eventId = getEventFromStorage();
      return authRequest()
        .get(
          `/api/business/v1/events/${eventId}/participants/search?query=${keyword}`,
        )
        .then((res) => {
          return new Promise((resolve) =>
            resolve(participantTransformerCollection(res.data.participants)),
          );
        });
    } catch (e) {}
  };

  const handleFieldChange = () => calculateTotalServicePrice();

  const handleServiceDelete = (form, field, cb) => {
    const appointmentServiceId = form.getFieldValue([
      'services',
      field.fieldKey,
      'id',
    ]);
    if (!appointment || !appointmentServiceId) {
      cb(field.name);
      return;
    }
    const eventId = getEventFromStorage();
    authRequest()
      .delete(
        `/api/business/v1/${eventId}/agenda_appointments/${appointment.id}/agenda_service/${appointmentServiceId}`,
      )
      .then((res) => {
        cb(field.name);
      });
  };

  const getActualPartecipant = () => {
    if (!appointment) return editPartecipant.current;
    return (
      editPartecipant.current ||
      participantTransformerResource(appointment.participant)
    );
  };

  // manage no operator
  const [isOperator, setIsOperator] = useState(
    appointment ? appointment.operator : true,
  );

  const toggleIsOperator = () => {
    setIsOperator(!isOperator);
  };

  // Get All Service For The Staff Even Ones that are not performed by user
  const serviceLists = allServices.filter((item) => {
    try {
      const appointmentServiceIds =
        appointment?.services?.map((item) => item.service_id) ?? [];
      const allServiceIds = services.map((i) => i.id);
      const servicesToShow = [...allServiceIds, ...appointmentServiceIds];
      return staff.id === 0 ? true : servicesToShow.includes(item.id);
    } catch (error) {
      return true;
    }
  });

  return (
    <>
      <Modal
        wrapClassName="modal-bg"
        title={title}
        open={open}
        onCancel={closeModal}
        width={540}
        closable={false}
        footer={footer}
        maskClosable={false}
      >
        <Spin spinning={!!scheduleLoading.length || loading} delay={200}>
          <ErrorRenderHandler>
            <Form
              initialValues={initialValues}
              form={form}
              onFinish={handleUpdateOrCreateSchedule}
              autoComplete="off"
              onFieldsChange={handleFieldChange}
            >
              <Row align="middle">
                <Col span={16}>
                  <Form.Item
                    className="my-0 py-2"
                    name={'participantId'}
                    labelAlign="left"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 0 }}
                    rules={[{ required: true }]}
                  >
                    <SearchAutocomplete
                      placeholder={t(
                        'calendar.repititivemodal.placeholder.name',
                      )}
                      minSearchLength={3}
                      currentOption={
                        appointment
                          ? participantTransformerResource(
                              appointment?.participant,
                            )
                          : null
                      }
                      fetchOptions={fetchParticipant}
                      onChange={participantChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Button
                    style={{
                      width: '100%',
                      backgroundColor: newPartecipant ? '#ccc' : '#fff',
                    }}
                    size="large"
                    onClick={() => {
                      setNewPartecipant(!newPartecipant);
                    }}
                  >
                    <div style={{ color: '#444' }}>N</div>
                  </Button>
                </Col>

                <Col span={3}>
                  <Button
                    style={{ width: '100%' }}
                    size="large"
                    onClick={() => {
                      if (!getActualPartecipant()) return false;
                      setEditClientModal(true);
                      setShowClientModal(true);
                    }}
                  >
                    <img src={EditIcon} alt="Edit Icon" />
                  </Button>
                </Col>

                <Col span={3}>
                  <Button
                    style={{ width: '100%' }}
                    size="large"
                    onClick={() => {
                      setEditClientModal(false);
                      setShowClientModal(true);
                    }}
                  >
                    <img src={PlusIcon} alt="Plus Icon" />
                  </Button>
                </Col>

                <Col span={24} className="mt-3 mb-2">
                  <Form.Item
                    className="my-0 pb-2 pt-2"
                    labelAlign="left"
                    name={'telephone'}
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 0 }}
                  >
                    <Input
                      readOnly={true}
                      placeholder={t(
                        'calendar.repititivemodal.placeholder.telephone',
                      )}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.List rules={[listRules]} name={'services'}>
                {(fields, { add, remove }, { errors }) => (
                  <div>
                    <Typography.Title level={5} className="px-2 py-0 my-0">
                      <span>{t('calendar.appointmentmodal.add')}</span>
                      <Button
                        className="border-0"
                        ghost={true}
                        onClick={() => add()}
                      >
                        <img src={PlusIcon} alt="Plus Icon" />
                      </Button>
                    </Typography.Title>

                    <div className="service-cards mt-2">
                      {fields.map((field) => (
                        <ServiceCard
                          key={`service-card-${field.fieldKey}-${field.name}`}
                          form={form}
                          className="appear-effect"
                          staff={staff}
                          serviceLists={serviceLists}
                          // serviceLists={staff.id === 0 ? allServices : services}
                          field={field}
                          isOperator={isOperator}
                          toggleIsOperator={toggleIsOperator}
                          removeCard={() =>
                            handleServiceDelete(form, field, remove)
                          }
                        />
                      ))}
                    </div>
                    {!!errors.length && (
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div className="alert">{errors[0]}</div>
                      </div>
                    )}
                  </div>
                )}
              </Form.List>

              <Col span={24}>
                <div className="w-100 d-flex justify-content-between">
                  <div>
                    <p className="text-bold font-14">
                      {t('calendar.appointmentmodal.total')}
                    </p>
                  </div>
                  <div>
                    {t('common:currency')} {totalPrice}
                  </div>
                </div>
              </Col>

              <RejectAppointmentModal
                open={openRejectModal}
                closeModal={() => setRejectModal(false)}
                submit={removeSchedule}
                reasons={deleteReasons}
                closable={false}
              />
            </Form>
          </ErrorRenderHandler>
        </Spin>
      </Modal>

      {showRepeatModal && (
        <RecurringModal
          open={showRepeatModal}
          appointment={appointment}
          onSubmit={handleRecurringData}
          closeModal={() => setShowRepeatModal(false)}
          recurringData={getRecurringModalData()}
        />
      )}

      {showClientModal && (
        <Modal
          wrapClassName="modal-bg"
          title={
            <Row align="middle" className="pt-3">
              <Col span={2}></Col>
              <Col span={20} className="text-center">
                {editClientModal
                  ? t('calendar.clientmodal.titleUpdate')
                  : t('calendar.clientmodal.titleCreate')}
              </Col>
              <Col span={2}>
                <Button
                  ghost={true}
                  onClick={() => setShowClientModal(false)}
                  className="border-0"
                >
                  <span className="close"></span>
                </Button>
              </Col>
            </Row>
          }
          centered
          footer={null}
          open={showClientModal}
          onCancel={() => setShowClientModal(false)}
          closable={false}
          maskClosable={true}
          width={600}
        >
          <ErrorRenderHandler>
            <Spin spinning={!!scheduleLoading.length || loading} delay={200}>
              <ClientForm
                mode={editClientModal ? EDIT_MODE : CREATE_MODE}
                initialValue={editClientModal ? getActualPartecipant() : null}
                onSubmit={editClientModal ? updateUser : createUser}
                customLayout={{ wrapperCol: { span: 14 } }}
              />
            </Spin>
          </ErrorRenderHandler>
        </Modal>
      )}
    </>
  );
}

ScheduleModal.propTypes = {
  open: PropTypes.bool,
  dateOption: PropTypes.object,
  staff: PropTypes.object,
  closeModal: PropTypes.func,
  appointment: PropTypes.object,
};

export default ScheduleModal;
