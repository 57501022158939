import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { updateError } from '../../components/util/Helper';
import {
  fetchDeleteAppointmentReasonRequest,
  fetchSchedulesRequest,
  fetchStatusColorsRequest,
  updateDraggedScheduleRequest,
  updateScheduleRequest,
} from './CalendarApi';
import {
  scheduleTransformerCollection,
  calendarStaffTransformerCollection,
} from '../../transformers/calendarStaffTransformer';
import statusColors from '../../components/util/statusColors';

const query = new URLSearchParams(window.location.search);
let date = query.get('from');
date = moment(date || new Date()).format('YYYY-MM-DD');

const initialState = {
  loading: false,
  fetchingData: [],
  error: {
    hasError: false,
    status: 0,
    data: null,
  },
  timeZone: 'Europe/Rome', // Shop Detail
  date,
  dailyView: true,
  schedules: [],
  staff: [],
  legend: [],
  shop: [],
  deleteAppointmentReasons: [],
};

export const fetchAllSchedules = createAsyncThunk(
  'calendar/fetchSchedules',
  async (date) => {
    let opt = date.requestConfig ?? {};
    const response = await fetchSchedulesRequest(
      date.startDate,
      date.endDate,
      opt,
    );

    // The value we return becomes the `fulfilled` action payload
    if (response.error) {
      return response;
    } else {
      let dta = response?.data?.data || null;

      // "one_service_on_appointment" flag in localStorage
      if (dta) {
        localStorage.setItem(
          'one_service_on_appointment',
          dta['one_service_on_appointment'] ? 'yes' : '',
        );

        if (dta?.hairdresser)
          localStorage.setItem('hairdresser_shop_name', dta?.hairdresser || '');

        //  "manual_status_appointment" flag to manage change status automatic/manual
        const msa = dta['manual_status_appointment'] ? 'yes' : '';
        localStorage.setItem('manual_status_appointment', msa);

        // ! in msa x test
        if (msa && dta.schedules) {
          //console.log('fetchSchedules', dta);
          dta.schedules.forEach((item) => {
            // not set => appointment (null) => with operator / recurring / no operator
            if (!item.appointment_status) {
              item.color = statusColors.withOperator; // orange
              if (item.recurring) item.color = statusColors.recurring; // red
              if (!item.operator) item.color = statusColors.noOperator; // cyan
            }
            // set to arrived (green)
            if (item.appointment_status === 'early_arrived') {
              item.color = statusColors.onGoing; // green
            }
          });
        }
      }
      return response.data;
    }
  },
);

export const fetchAllDeleteReasonsForSchedules = createAsyncThunk(
  'calendar/fetchAllDeleteReasonsForSchedules',
  async () => {
    const response = await fetchDeleteAppointmentReasonRequest();
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const fetchAllStatusColors = createAsyncThunk(
  'calendar/fetchStatusColors',
  async () => {
    const response = await fetchStatusColorsRequest();

    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

export const updateDraggedSchedule = createAsyncThunk(
  'calendar/updateDraggedSchedule',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(updateScheduleById(payload.new));

    const response = await updateDraggedScheduleRequest(payload.new);

    // The value we return becomes the `fulfilled` action payload
    return response.error
      ? { ...response, ...{ old: payload.old } }
      : response.data;
  },
);

export const updateAgendaSchedule = createAsyncThunk(
  'calendar/updateSchedule',
  async (payload) => {
    const response = await updateScheduleRequest(payload);

    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  },
);

const getTimezone = (timezone) => {
  const timezones = [
    'Europe/Andorra',
    'Europe/Tirane',
    'Europe/Vienna',
    'Europe/Brussels',
    'Europe/Sofia',
    'Europe/Minsk',
    'Europe/Zurich',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'Europe/Tallinn',
    'Europe/Madrid',
    'Europe/Helsinki',
    'Europe/Paris',
    'Europe/London',
    'Europe/Gibraltar',
    'Europe/Athens',
    'Europe/Budapest',
    'Europe/Dublin',
    'Europe/Rome',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Europe/Malta',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Europe/Warsaw',
    'Europe/Lisbon',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Europe/Stockholm',
    'Europe/Istanbul',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
  ];
  const mainTimezone = timezone?.split(')')[1] || '';
  return timezones.find((i) =>
    i.toLocaleLowerCase().includes(mainTimezone.trim().toLocaleLowerCase()),
  );
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeDate: (state, action) => {
      state.date = action.payload;
    },

    changeToDailyView: (state) => {
      if (state.dailyView !== true) {
        state.dailyView = true;
      }
    },

    changeToWeeklyView: (state) => {
      if (state.dailyView !== false) {
        state.dailyView = false;
      }
    },

    updateScheduleById: (state, action) => {
      const data = action.payload;
      const newSchedules = state.schedules.map((item) => {
        return item.id === data.id ? data : item;
      });
      state.schedules = scheduleTransformerCollection(newSchedules);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updateAgendaSchedule.pending, (state) => {
        state.fetchingData = ['updateAgendaSchedule', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(updateAgendaSchedule.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'updateAgendaSchedule',
        );
        state.loading = !!state.fetchingData.length;

        if (updateError(action.payload, state)) {
          return;
        }

        const data = action.payload;
        const schedules = state.schedules.map((item) => {
          if (data.id === item.id) {
            item.appointment_status = data.appointment_status;
            item.color = data.color;

            if (data.start_date) item.start_date = data.start_date;
            if (data.end_date) item.end_date = data.end_date;
          }
          return item;
        });
        state.schedules = schedules;
      })
      .addCase(fetchAllStatusColors.pending, (state) => {
        state.fetchingData = ['fetchAllStatusColors', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(fetchAllStatusColors.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchAllStatusColors',
        );
        state.loading = !!state.fetchingData.length;

        if (updateError(action.payload, state)) {
          return;
        }
        const data = action.payload.appointment_status;

        state.legend = data;
      })

      .addCase(updateDraggedSchedule.pending, (state) => {
        state.fetchingData = ['updateDraggedSchedule', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(updateDraggedSchedule.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'updateDraggedSchedule',
        );
        state.loading = !!state.fetchingData.length;

        if (updateError(action.payload, state)) {
          updateScheduleById(action.payload.old);
          return;
        }

        const data = action.payload;
        data.staff_id = data.staff.id;
        const newSchedules = state.schedules.map((item) =>
          item.id === data.id ? data : item,
        );
        state.schedules = scheduleTransformerCollection(newSchedules);
      })

      .addCase(fetchAllSchedules.pending, (state) => {
        state.fetchingData = ['fetchAllSchedules', ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })

      .addCase(fetchAllSchedules.fulfilled, (state, action) => {
        if (action.payload.error && action.payload.status === 1) return;

        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchAllSchedules',
        );
        state.loading = !!state.fetchingData.length;
        if (updateError(action.payload, state)) {
          return;
        }
        const data = action.payload.data;

        state.schedules = scheduleTransformerCollection(data.schedules);
        state.shop = data.shop;
        state.timeZone = getTimezone(data.timezone);
        momentTimezone.tz.setDefault(state.timeZone);
        const isClosed =
          state.dailyView && data.shop.length && data.shop[0].closed;
        // Remove staff is shop is closed for daily view
        // Adding shop holiday as staff

        const getShopBreakTime = (shop) =>
          shop
            .map((item) =>
              item.break_start.length
                ? {
                    id: `generated-holiday-${item.date}-${item.break_start}-shop-break`,
                    start_date: `${item.date} ${item.break_start}`,
                    end_date: `${item.date} ${item.break_end}`,
                  }
                : null,
            )
            .filter((x) => x);

        const getShopExtraordinaryBreakTime = (shop) =>
          shop
            .map((item) =>
              item.extra_close_end.length
                ? {
                    id: `generated-extra-holiday-${item.date}-${item.extra_close_start}-shop-break`,
                    start_date: `${item.date} ${item.extra_close_start}`,
                    end_date: `${item.date} ${item.extra_close_end}`,
                  }
                : null,
            )
            .filter((x) => x);

        const holidays = [
          ...getShopBreakTime(data.shop),
          ...getShopExtraordinaryBreakTime(data.shop),
        ];

        let newStaff = holidays.length
          ? data.staffs.map((item) => {
              item.holidays =
                item.holidays && item.holidays.length
                  ? [...item.holidays, ...holidays]
                  : holidays;
              return item;
            })
          : data.staffs;

        // Removing staff with id 0 if schedule is not present
        if (!data.schedules.find((i) => i.staff_id === 0)) {
          newStaff = newStaff.filter((i) => i.id);
        }
        state.staff = isClosed
          ? []
          : calendarStaffTransformerCollection(newStaff);
      })

      .addCase(fetchAllDeleteReasonsForSchedules.pending, (state) => {
        state.fetchingData = [
          'fetchAllDeleteReasonsForSchedules',
          ...state.fetchingData,
        ];
        state.loading = !!state.fetchingData.length;
      })

      .addCase(fetchAllDeleteReasonsForSchedules.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(
          (item) => item !== 'fetchAllDeleteReasonsForSchedules',
        );
        state.loading = !!state.fetchingData.length;

        if (updateError(action.payload, state)) {
          return;
        }

        const data = action.payload.delete_notes;
        state.deleteAppointmentReasons = data;
      });
  },
});

export const {
  updateScheduleById,
  changeDate,
  changeToDailyView,
  changeToWeeklyView,
} = calendarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file.

export const selectDate = (state) => state.calendar.date;
export const selectShopTime = (state) => state.calendar.shop;
export const selectDeleteReasons = (state) =>
  state.calendar.deleteAppointmentReasons;
export const selectCalendarState = (state) => state.calendar.loading;
export const isDailyView = (state) => state.calendar.dailyView;
export const selectSchedules = (state) => state.calendar.schedules;
export const selectCalendarColumn = (state) => state.calendar.staff;
export const selectColors = (state) => state.calendar.legend;
export const selectTimezone = (state) =>
  state?.calendar?.timeZone || 'Europe/Rome';

export default calendarSlice.reducer;
