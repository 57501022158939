import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import {
  Button,
  Calendar,
  Card,
  Dropdown,
  Menu,
  Modal,
  Popover,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import Item from 'antd/lib/list/Item';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../features/calendar/calendarSlice';
import {
  CalendarIcon,
  GreaterThanIcon,
  LessThanIcon,
  RightArrow,
  Sidebar,
} from './../index';
import './CalendarHeader.less';

/**
 * Calendar header component
 *
 * @param {*} param0 Calendar Header
 * @returns {JSX}
 */
function CalendarHeader({
  onDateChanged,
  allowPrevDay,
  date = null,
  dailyCalendar = true,
  onMonthClick,
  allStatusColors = [],
  changeView,
}) {
  const timeZone = useSelector(selectTimezone);
  const [momentDate, setMomentDate] = useState();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [currentTime, setCurrentTime] = useState();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const enablePreviousDay = Boolean(allowPrevDay);

  useEffect(() => {
    let interval = null;
    setMomentDate(moment(date).tz(timeZone, true));
    setCurrentTime(moment().tz(timeZone));

    let timeout = setTimeout(
      () => {
        interval = setInterval(
          () => setCurrentTime(moment().tz(timeZone)),
          60000,
        );
      },
      60 - moment().format('s'),
    );

    return () => {
      interval && clearInterval(interval);
      timeout && clearTimeout(timeout);
    };
  }, [date, timeZone]);

  const changeDailySelect = (val) =>
    typeof changeView === 'function' && changeView(!!val);

  const handleMonthClick = (e) => {
    e.preventDefault();
    setVisible(true);
    // Open Calendar Show Dates And Change To Those Date
    typeof onMonthClick === 'function' && onMonthClick();
  };

  const dateChangeClicked = (e, type, day, duration) => {
    e.preventDefault();
    if (type === 'prev' && !allowPrevDay) return;
    const newDate =
      type === 'prev'
        ? momentDate.clone().subtract(day, duration)
        : momentDate.clone().add(day, duration);
    typeof onDateChanged === 'function' && onDateChanged(newDate);
  };

  function onDateSelect(newDate) {
    typeof onDateChanged === 'function' && onDateChanged(newDate);
  }

  const colors = (
    <Menu>
      <div className="list-group">
        {!!allStatusColors.length &&
          allStatusColors.map((item) => (
            <Menu.Item key={item.status}>
              <Space>
                <div
                  style={{
                    background: item.color,
                    width: 50,
                    height: 20,
                    ...(item.border_color
                      ? { border: `${item.border_color} 4px solid` }
                      : {}),
                  }}
                ></div>
                <span className="text-uppercase texPrimary px-2">
                  {item.status}
                </span>
              </Space>
            </Menu.Item>
          ))}
      </div>
    </Menu>
  );

  const scrollCalendarBody = (option) => {
    let calendarScroller = document.getElementById('calendarscroller');
    if (!calendarScroller) return;
    let inw = window.innerWidth;

    if (inw >= 1200) return;
    let scrollBy = inw * 0.5;

    option === 'left' &&
      calendarScroller.scroll({
        left: calendarScroller.scrollLeft - scrollBy,
        behavior: 'smooth',
      });
    option === 'right' &&
      calendarScroller.scroll({
        left: calendarScroller.scrollLeft + scrollBy,
        behavior: 'smooth',
      });
  };

  const scrollToSideArrows = (
    <div className="container-row d-flex flex-row justify-content-evenly align-items-center">
      <Tooltip placement="topLeft" title={t('calendar.scroll_left')}>
        <Button
          ghost={true}
          className="calendar-prev-button"
          onClick={(e) => scrollCalendarBody('left')}
        >
          <ArrowLeftOutlined
            style={{ color: 'black', fontSize: 18 }}
            alt="Prev Body scroll"
          />
        </Button>
      </Tooltip>

      <Tooltip placement="topLeft" title={t('calendar.scroll_right')}>
        <Button
          ghost={true}
          data-testid="next-button"
          onClick={(e) => scrollCalendarBody('right')}
        >
          <ArrowRightOutlined
            style={{ color: 'black', fontSize: 18 }}
            alt="Next Body scroll"
          />
        </Button>
      </Tooltip>
    </div>
  );

  const handleMonthChange = (head) => {
    let month = head.value.clone().subtract(1, 'month');
    if (allowPrevDay) {
      head.onChange(month);
      return;
    }
    if (moment().isSameOrBefore(month, 'date')) {
      head.onChange(month);
    }
  };

  const items = [
    {
      key: 'daily',
      label: (
        <Item key="daily" onClick={() => changeDailySelect(true)}>
          {t('calendar.daily')}
        </Item>
      ),
    },
    {
      key: 'weekly',
      label: (
        <Item key="weekly" onClick={() => changeDailySelect(false)}>
          {t('calendar.weekly')}
        </Item>
      ),
    },
  ];

  if (!momentDate) return <></>;

  return (
    <div className="calendar-top-header noselect">
      <div className="container-row calendar-select d-flex flex-row justify-content-between align-items-center">
        {/* Open date with calendar view in modal  */}
        <div
          className="m-auto cursor-pointer"
          style={{ margin: '0 auto' }}
          onClick={handleMonthClick}
        >
          <Space>
            <img src={CalendarIcon} alt="Calendar icon" />
            <strong className="text-uppercase">
              {momentDate?.format('MMM')}
            </strong>
          </Space>
        </div>

        <Modal
          width={600}
          open={visible}
          className="bg-light-yellow datepicker-calendar"
          onOk={() => setVisible(false)}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <Card className="bg-light-yellow">
            <Calendar
              className="calendar-header"
              headerRender={(head) => {
                return (
                  <div>
                    <Typography.Paragraph className="my-0 pb-2 text-capitalize px-2">
                      {head.value.format('MMMM')}
                    </Typography.Paragraph>

                    <Button
                      className="calendar-arrow arrow-left"
                      onClick={() => handleMonthChange(head)}
                    >
                      <RightOutlined />
                    </Button>

                    <Button
                      className="calendar-arrow arrow-right"
                      onClick={() =>
                        head.onChange(head.value.clone().add(1, 'month'))
                      }
                    >
                      <LeftOutlined />
                    </Button>
                  </div>
                );
              }}
              locale={'it'}
              fullscreen={false}
              disabledDate={(current) => {
                if (enablePreviousDay) return false;
                return current && current && current.isBefore(moment(), 'date');
              }}
              dateFullCellRender={(date) => (
                <div
                  className={`calendar-date ${
                    date.day() === 6 ? 'calendar-leave' : ''
                  }`}
                >
                  {date.format('DD')}
                </div>
              )}
              onSelect={onDateSelect}
            />
          </Card>
        </Modal>
      </div>

      <div
        className="d-flex flex-row container-row menu-items"
        style={{ flex: 1 }}
      >
        <div className="container-row d-flex flex-row justify-content-between align-items-center">
          <Tooltip placement="topLeft" title={t('calendar.prev_day')}>
            <Button
              ghost={true}
              className="calendar-prev-button"
              data-testid="prev-button"
              onClick={(e) => dateChangeClicked(e, 'prev', 1, 'day')}
            >
              <img
                src={LessThanIcon}
                alt="Go To Previous Date"
                style={!enablePreviousDay ? { filter: 'invert(1)' } : {}}
              />
            </Button>
          </Tooltip>
          {dailyCalendar ? (
            <p className="m-0 day text-uppercase">
              {momentDate.tz(timeZone).format('ddd DD')}
            </p>
          ) : (
            <Space>
              <span className="day text-uppercase">
                {momentDate.tz(timeZone).format('ddd DD')}
              </span>
              <span className="px-2">
                <img src={RightArrow} alt="to" width="12" />
              </span>
              <span className="day text-uppercase">
                {momentDate.clone().add(6, 'day').format('ddd DD')}
              </span>
            </Space>
          )}
          <Tooltip placement="topLeft" title={t('calendar.next_day')}>
            <Button
              ghost={true}
              data-testid="next-button"
              className="calendar-next-button"
              onClick={(e) => dateChangeClicked(e, 'next', 1, 'day')}
            >
              <img src={GreaterThanIcon} alt="Go To Next Date" />
            </Button>
          </Tooltip>
        </div>

        <div className="container-row d-flex flex-row justify-content-between align-items-center">
          <Tooltip placement="topLeft" title={t('calendar.time')}>
            <p className="m-0 mx-auto time">{currentTime.format('HH:mm')}</p>
          </Tooltip>
        </div>

        <div
          className="container-row d-flex flex-row justify-content-center align-items-center"
          style={{ minWidth: '154px' }}
        >
          <Dropdown className="calendar-view-dropdown" menu={{ items }}>
            <Button value="default" className="secondary-button-outline w-75">
              {dailyCalendar ? t('calendar.daily') : t('calendar.weekly')}
            </Button>
          </Dropdown>
        </div>

        {dailyCalendar && scrollToSideArrows}

        <div className="container-row d-flex flex-row justify-content-between align-items-center">
          <Popover
            placement="bottom"
            className={'border-0 p-0 m-0 mx-auto'}
            content={colors}
            trigger="click"
          >
            <Button className="secondary-button-ghost">
              <span className="d-none d-lg-block">
                {t('calendar.appointment_status')}
              </span>
            </Button>
          </Popover>
        </div>
      </div>

      <div className="container-row d-flex hamburger-menu flex-row justify-content-between align-items-center">
        <Button
          ghost={true}
          className="menu-icon"
          onClick={(e) => setOpenSidebar(true)}
        >
          <MenuOutlined />
        </Button>

        <Sidebar
          closeSidebar={() => setOpenSidebar(false)}
          open={openSidebar}
        />
      </div>
    </div>
  );
}

CalendarHeader.propTypes = {
  onDateChanged: PropTypes.func,
  date: PropTypes.string,
  dailyCalendar: PropTypes.bool,
  onMonthClick: PropTypes.func,
  allowPrevDay: PropTypes.bool,
  allStatusColors: PropTypes.array,
  changeView: PropTypes.func,
};

export default React.memo(CalendarHeader);
